"use strict";
// hamburger menu and mobile menu
const hamburger = document.getElementById('hamburger');
const mobileNavLinks = document.querySelectorAll('.mobile-nav-link');
const mobileMenu = document.getElementById('mobile-menu');
const toggleMobileMenu = () => {
    hamburger && hamburger.classList.toggle('active');
    mobileMenu && mobileMenu.classList.toggle('active');
};
if (hamburger) {
    hamburger.addEventListener('click', toggleMobileMenu);
    if (mobileNavLinks.length > 0) {
        mobileNavLinks.forEach((e) => e.addEventListener('click', toggleMobileMenu));
    }
}
