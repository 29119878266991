import { forEach } from 'lodash';
// adding in nested ul since for some reason webflow doesn't support this...?
const blogContent = document.querySelector('.blog-content');
if (blogContent) {
    const listElements = document.querySelectorAll('ul');
    if (listElements.length > 0) {
        listElements.forEach((e) => {
            if (e.children.length === 0)
                return;
            forEach(e.children, (listItem) => {
                let text = listItem.textContent;
                if (!text)
                    return;
                // indentation icon - replace each ~ with a margin-left: 40/20px (increment)
                if (text.includes('~')) {
                    const count = (text.match(/~/gi) || []).length;
                    const marginAmount = window.innerWidth <= 568 ? 20 : 40;
                    text = text.replace(/~/gi, '');
                    // @ts-ignore
                    listItem.style.marginLeft = `${marginAmount * count}px`;
                }
                // featured icon - add featured class (colors it)
                if (text.includes('`')) {
                    text = text.replace(/`/gi, '');
                    listItem.classList.add('featured');
                }
                listItem.textContent = text;
            });
        });
    }
}
