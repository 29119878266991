"use strict";
// typing word for header
const typingWord = document.getElementById('typing-word');
if (typingWord) {
    const text = typingWord.innerText;
    let count = 0;
    let backspace = false;
    typingWord.innerText = '';
    setInterval(() => {
        if (count === text.length) {
            backspace = true;
        }
        else if (backspace && count === 0) {
            backspace = false;
        }
        if (backspace) {
            const wordAsArray = typingWord.innerText.split('');
            const popped = wordAsArray.pop(); // reassign to not overwrite word
            const string = wordAsArray.join('');
            typingWord.innerText = string;
            count--;
        }
        else {
            typingWord.innerText += text[count];
            count++;
        }
    }, 750);
}
