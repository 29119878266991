import { get } from 'lodash';
// elipses
const elipsesItems = document.querySelectorAll('.elipses');
if (elipsesItems.length > 0) {
    const maxCharacterLength = 40;
    elipsesItems.forEach((e) => {
        var _a;
        const trimmedText = `${(_a = e.textContent) === null || _a === void 0 ? void 0 : _a.slice(0, maxCharacterLength).trim()}...`;
        e.textContent = trimmedText;
    });
}
// cms navigation buttons
const prevButtons = document.querySelectorAll('.prev-button');
const nextButtons = document.querySelectorAll('.next-button');
const collectionList = document.getElementById('collection-list');
const currentItem = collectionList && collectionList.querySelector('.w--current');
if ((prevButtons.length > 0 || nextButtons.length > 0) && currentItem) {
    // get previous sibling of current item
    const previousSibling = get(currentItem, ['parentElement', 'previousSibling', 'children', '0']);
    // get next sibling of current item
    const nextSibling = get(currentItem, ['parentElement', 'nextSibling', 'children', '0']);
    // set href for prev and next buttons
    previousSibling && prevButtons.forEach((e) => e.setAttribute('href', previousSibling.getAttribute('href')));
    nextSibling && nextButtons.forEach((e) => e.setAttribute('href', nextSibling.getAttribute('href')));
}
