import Glide from '@glidejs/glide';
const slider = document.getElementById('glide');
if (slider) {
    const breakpoint = 1026;
    const type = window.innerWidth > breakpoint ? 'slider' : 'carousel';
    const perView = window.innerWidth > breakpoint ? 2 : 1;
    const slideLength = Math.round(slider.querySelectorAll('.glide__slide:not(.glide__slide--clone)').length / perView);
    const glidePrev = document.getElementById('glide-prev');
    const glideNext = document.getElementById('glide-next');
    const glideProgressFiller = document.getElementById('glide-progress-filler');
    const lastSlide = slideLength - 1;
    let translateNumber = 0;
    let currentSlide = 0;
    const glide = new Glide(slider, {
        type,
        perView,
        gap: 25,
        rewind: false,
        dragThreshold: false,
        swipeThreshold: false,
        animationTimingFunc: 'cubic-bezier(0.680, -0.550, 0.265, 1.550)',
        peek: {
            before: 0,
            after: 0,
        },
    });
    // set width for amount of slides for slider progress bar
    if (glideProgressFiller) {
        // @ts-ignore
        glideProgressFiller.style.width = `${100 / slideLength}%`;
    }
    const handleGlideButtonClick = (isPrev = false) => {
        isPrev ? currentSlide-- : currentSlide++;
        // next button clicked at end
        if (currentSlide > lastSlide) {
            glide.go('=0');
            translateNumber = 0;
            currentSlide = 0;
            // prev button clicked at beginning
        }
        else if (currentSlide < 0) {
            glide.go(`=${lastSlide}`);
            currentSlide = lastSlide;
            translateNumber = (lastSlide / slideLength) * 100;
        }
        else {
            translateNumber = (currentSlide / slideLength) * 100;
        }
        // @ts-ignore - move progress bar
        glideProgressFiller.style.left = `${translateNumber}%`;
    };
    glidePrev && (glidePrev === null || glidePrev === void 0 ? void 0 : glidePrev.addEventListener('click', () => handleGlideButtonClick(true)));
    glideNext && (glideNext === null || glideNext === void 0 ? void 0 : glideNext.addEventListener('click', () => handleGlideButtonClick()));
    glide.mount();
}
